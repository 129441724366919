














import Vue from 'vue'

export default Vue.extend({
  components: {},
  props: {
    fullName: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
})
