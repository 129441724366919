































































import Vue from 'vue'
import LinkToModal from '@/components/panel/block/LinkToModal.vue'
import Section from '@/components/panel/Section.vue'
import Title from '@/components/panel/Title.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'

export default Vue.extend({
  components: {
    LinkToModal,
    Section,
    Title,
    IconInfoCircle,
  },
  props: {
    isModal: {
      type: Boolean,
      required: true,
    },
    prescription: {
      type: Object,
      required: true,
    },
  },

  computed: {},
})
