






















































import Vue, { PropType } from 'vue'

import Section from '@/components/layouts/main/Section.vue'
import IconLogo from '@/components/icons/IconLogo.vue'
import { Letter } from 'MedicalReport/getters/__types__/report-details'

export default Vue.extend({
  components: {
    Section,
    IconLogo,
  },

  props: {
    letter: {
      type: Object as PropType<Letter>,
      required: true,
    },
  },
})
