





































import Vue from 'vue'

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },

  data() {
    return {
      cls1:
        'fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 20px;',
      cls2:
        'stroke-linecap: round; stroke-linejoin: round; stroke-width: 20px;',
    }
  },
})
