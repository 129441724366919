














































































import Vue, { PropType } from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import TitleAndSubTitle from '@/components/layouts/header/TitleAndSubTitle.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import IconCross from '@/components/icons/IconCross.vue'
import Section from '@/components/panel/Section.vue'
import Title from '@/components/panel/Title.vue'
import TextSmall from '@/components/panel/TextSmall.vue'
import LinkToPanel from '@/components/panel/LinkToPanel.vue'
import List from '@/components/panel/List.vue'
import Spacer from '@/components/panel/Spacer.vue'
import PrescriptionLink from '@/components/dashboard/panels/right/PrescriptionLink.vue'

import { ReportDetails } from 'MedicalReport/getters/__types__/report-details'

export default Vue.extend({
  components: {
    BaseSidePanel,
    TitleAndSubTitle,
    ButtonIcon,
    IconCross,
    Section,
    Title,
    TextSmall,
    LinkToPanel,
    List,
    Spacer,
    PrescriptionLink,
  },

  props: {
    reportDetails: {
      type: Object as PropType<ReportDetails>,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    causesTitle() {
      let word = 'Motif'
      if (this.reportDetails.causes.length > 1) {
        word = 'Motifs'
      }
      return word
    },
    diagnosisTitle() {
      let word = 'Diagnostic probable'
      if (this.reportDetails.diagnosis.length > 1) {
        word = 'Diagnostics probables'
      }
      return word
    },
  },

  methods: {},
})
