






















































































import Vue from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import TitleAndSubTitle from '@/components/layouts/header/TitleAndSubTitle.vue'
import Section from '@/components/panel/Section.vue'
import Title from '@/components/panel/Title.vue'
import TextSmall from '@/components/panel/TextSmall.vue'
import List from '@/components/panel/List.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import FooterLogo from '@/components/panel/FooterLogo.vue'
import LinkToPanelAndIcon from '@/components/panel/block/LinkToPanelAndIcon.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'
import IconGirl from '@/components/icons/IconGirl.vue'
import IconGirlInverse from '@/components/icons/IconGirlInverse.vue'
import IconBoy from '@/components/icons/IconBoy.vue'
import IconBoyInverse from '@/components/icons/IconBoyInverse.vue'
import Spacer from '@/components/panel/Spacer.vue'

export default Vue.extend({
  components: {
    BaseSidePanel,
    TitleAndSubTitle,
    Section,
    Title,
    TextSmall,
    List,
    ButtonIcon,
    FooterLogo,
    LinkToPanelAndIcon,
    IconInfoCircle,
    IconGirl,
    IconGirlInverse,
    IconBoy,
    IconBoyInverse,
    Spacer,
  },

  props: {
    patientProfile: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    antecedentTitle() {
      let word = 'Antécédent'
      if (this.patientProfile.antecedents.length > 1) {
        word = 'Antécédents'
      }
      return word
    },
  },

  methods: {},
})
