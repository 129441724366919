





































import Vue from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import AboutBody from '@/components/login/panels/AboutBody.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'
import FooterLogo from '@/components/panel/FooterLogo.vue'
import ExternalLinkWithIcon from '@/components/panel/block/ExternalLinkWithIcon.vue'
import IconMail from '@/components/icons/IconMail.vue'

export default Vue.extend({
  components: {
    BaseSidePanel,
    HeaderTitle,
    AboutBody,
    ButtonIcon,
    IconInfoCircle,
    FooterLogo,
    ExternalLinkWithIcon,
    IconMail,
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
