var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b border-default-100"},[_c('LinkToModal',{attrs:{"text":"Ordonnance","border":"top"},on:{"action":function($event){_vm.isModal
        ? _vm.$emit('toggleModal')
        : _vm.$emit('toggleModal', _vm.prescription.pdfFileInBase64)}}},[_vm._v(_vm._s(_vm.isModal ? _vm.$t('tooltips.close') : 'Ouvrir'))]),(_vm.prescription.prescriptedDrugs)?_c('Section',[_c('Title',{attrs:{"text":_vm.prescription.prescriptedDrugs.length >= 2
          ? 'Prescriptions'
          : 'Prescription'}}),_c('ul',{staticClass:"list-none list-inside"},_vm._l((_vm.prescription.prescriptedDrugs),function(item,index){return _c('li',{key:index},[_vm._v(" • "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-start",value:({
            content: ("\n              <ul class='list-none list-inside'>\n                <li>\n                  <span class='font-normal'>" + (item.molecules.length >= 2 ? 'Molécules' : 'Molécule') + " : </span>\n                  " + (item.molecules) + "\n                </li>\n                <li>\n                  <span class='font-normal'>Présentation : </span>\n                  " + (item.drug) + "\n                </li>\n                <li>\n                  <span class='font-normal'>Posologie : </span>\n                  " + (item.dosage) + "\n                </li>\n                <li>\n                  <span class='font-normal'>Durée : </span>\n                  " + (item.duration) + "\n                </li>\n              </ul>\n            "),
            classes: 'max-w-xs break-words',
          }),expression:"{\n            content: `\n              <ul class='list-none list-inside'>\n                <li>\n                  <span class='font-normal'>${\n                    item.molecules.length >= 2 ? 'Molécules' : 'Molécule'\n                  } : </span>\n                  ${item.molecules}\n                </li>\n                <li>\n                  <span class='font-normal'>Présentation : </span>\n                  ${item.drug}\n                </li>\n                <li>\n                  <span class='font-normal'>Posologie : </span>\n                  ${item.dosage}\n                </li>\n                <li>\n                  <span class='font-normal'>Durée : </span>\n                  ${item.duration}\n                </li>\n              </ul>\n            `,\n            classes: 'max-w-xs break-words',\n          }",modifiers:{"bottom-start":true}}]},[_vm._v(" "+_vm._s(item.brandedDrug)+" "),_c('IconInfoCircle',{staticClass:"inline-block text-primary",attrs:{"width":"14","height":"14"}})],1)])}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }