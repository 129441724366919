
















import Vue, { PropType } from 'vue'
import IconExternalLink from '@/components/icons/IconExternalLink.vue'

const borders = {
  top: 'border-t',
  bottom: 'border-b',
  both: 'border-t border-b',
}

export default Vue.extend({
  components: {
    IconExternalLink,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
      default: '#',
    },
    border: {
      type: String as PropType<'top' | 'bottom' | 'both' | undefined>,
    },
  },

  computed: {
    bordersManager(): string {
      if (this.border) {
        return borders[this.border]
      } else {
        return 'border-none'
      }
    },
  },
})
