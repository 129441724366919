





















import Vue, { PropType } from 'vue'

import BaseHeader from '@/components/layouts/BaseHeader.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import Modal from '@/components/layouts/main/Modal.vue'
import IconCross from '@/components/icons/IconCross.vue'

import { Modal as ModalType } from 'UI/__types__/state'

export default Vue.extend({
  components: {
    BaseHeader,
    HeaderTitle,
    ButtonIcon,
    IconCross,
    Modal,
  },

  props: {
    modal: {
      type: Object as PropType<ModalType>,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
