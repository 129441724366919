






import Vue from 'vue'

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
})
