

















import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'

// Types
import { MRActionNames } from 'MedicalReport/__types__/actions'
import { UIActionNames } from 'UI/__types__/actions'

// Components
import Dashboard from '@/components/dashboard/Dashboard.vue'
import AppSkeleton from '@/components/skeletons/AppSkeleton.vue'
import { AuthActionNames } from 'Auth/__types__/actions'

export default Vue.extend({
  components: {
    Dashboard,
    AppSkeleton,
  },

  computed: {
    ...mapState('ui', ['isLeftPanel', 'isRightPanel', 'modal']),
    ...mapState('medicalReport', ['isLoading']),
    ...mapGetters('medicalReport', ['getPatientProfile', 'getReportDetails']),
  },

  beforeMount() {
    this.getPatientRecord().then(() => {
      if (!this.getPatientProfile || !this.getReportDetails) {
        this.signOut()
      }
    })
  },

  methods: {
    ...mapActions('medicalReport', {
      getPatientRecord: MRActionNames.GET_MEDICAL_REPORT,
    }),
    ...mapActions('auth', {
      signOut: AuthActionNames.SIGN_OUT,
    }),

    ...mapActions('ui', {
      toggleLeftPanel: UIActionNames.TOGGLE_LEFT_PANEL,
      toggleRightPanel: UIActionNames.TOGGLE_RIGHT_PANEL,
      toggleModal: UIActionNames.TOGGLE_MODAL,
      setModalContent: UIActionNames.SET_MODAL_CONTENT,
    }),
    togglePDFModal(pdfFileInBase64: string) {
      if (
        !this.modal.isVisible &&
        pdfFileInBase64 !== this.modal.content?.body
      ) {
        this.setModalContent({
          header: 'Ordonnance',
          body: pdfFileInBase64,
        })
      }
      this.toggleModal()
    },
  },
})
