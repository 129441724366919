












































































import Vue, { PropType } from 'vue'

import BaseViewport from '@/components/layouts/BaseViewport.vue'
import BaseMain from '@/components/layouts/BaseMain.vue'
import BaseHeader from '@/components/layouts/BaseHeader.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import IconSidePanel from '@/components/icons/IconSidePanel.vue'
import IconOff from '@/components/icons/IconOff.vue'
import IconInfoCircle from '@/components/icons/IconInfoCircle.vue'
import LeftSidePanel from '@/components/dashboard/panels/left/LeftSidePanel.vue'
import RightSidePanel from '@/components/dashboard/panels/right/RightSidePanel.vue'
import Letter from '@/components/dashboard/Letter.vue'
import PDFModal from '@/components/dashboard/PDFModal.vue'

import { ReportDetails } from 'MedicalReport/getters/__types__/report-details'
import { PatientProfile } from 'MedicalReport/getters/__types__/patient-profile'
import { Modal as ModalType } from 'UI/__types__/state'

export default Vue.extend({
  components: {
    BaseViewport,
    BaseMain,
    BaseHeader,
    HeaderTitle,
    ButtonIcon,
    IconSidePanel,
    IconOff,
    IconInfoCircle,
    LeftSidePanel,
    RightSidePanel,
    Letter,
    PDFModal,
  },

  props: {
    modal: {
      type: Object as PropType<ModalType>,
      required: true,
    },
    isLeftPanel: {
      type: Boolean,
      required: true,
    },
    isRightPanel: {
      type: Boolean,
      required: true,
    },
    patientProfile: {
      type: Object as PropType<PatientProfile>,
      required: true,
    },
    reportDetails: {
      type: Object as PropType<ReportDetails>,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isModal(): boolean {
      return this.modal.isVisible
    },
  },

  methods: {},
})
