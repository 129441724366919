













import Vue, { PropType } from 'vue'

const borders = {
  top: 'border-t',
  bottom: 'border-b',
  both: 'border-t border-b',
}

export default Vue.extend({
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
    border: {
      type: String as PropType<'top' | 'bottom' | 'both' | undefined>,
    },
  },

  computed: {
    bordersManager(): string {
      if (this.border) {
        return borders[this.border]
      } else {
        return 'border-none'
      }
    },
  },
})
