


























import Vue, { PropType } from 'vue'

import BaseSidePanel from '@/components/layouts/BaseSidePanel.vue'
import ButtonIcon from '@/components/layouts/header/ButtonIcon.vue'
import HeaderTitle from '@/components/layouts/header/HeaderTitle.vue'
import IconCross from '@/components/icons/IconCross.vue'
import Section from '@/components/panel/Section.vue'
import Title from '@/components/panel/Title.vue'
import AgentProfile from '@/components/panel/block/AgentProfile.vue'
import Spacer from '@/components/panel/Spacer.vue'

import { Agent } from 'MedicalReport/getters/__types__/report-details'

export default Vue.extend({
  components: {
    BaseSidePanel,
    ButtonIcon,
    IconCross,
    Section,
    Title,
    HeaderTitle,
    AgentProfile,
    Spacer,
  },

  props: {
    agent: {
      type: Object as PropType<Agent>,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
