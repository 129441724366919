











import Vue, { PropType } from 'vue'

import MedicalReportDetails from '@/components/dashboard/panels/right/MedicalReportDetails.vue'
import AgentProfile from '@/components/dashboard/panels/right/AgentProfile.vue'
import { ReportDetails } from 'MedicalReport/getters/__types__/report-details'

// Create a SupervisorProfile component from AgentProfile component
const SupervisorProfile = AgentProfile

export default Vue.extend({
  components: {
    MedicalReportDetails,
    AgentProfile,
    SupervisorProfile,
  },

  props: {
    reportDetails: {
      type: Object as PropType<ReportDetails>,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      panelsList: [
        {
          component: 'MedicalReportDetails',
          props: { reportDetails: this.reportDetails },
        },
        {
          component: 'AgentProfile',
          props: { agent: this.reportDetails.agent },
        },
        {
          component: 'SupervisorProfile',
          props: { agent: this.reportDetails.supervisor },
        },
      ],
      rootPanelComponent: 'MedicalReportDetails',
      currentPanel: 'MedicalReportDetails',
    }
  },

  computed: {
    panelProperties(): Record<symbol, string> | undefined {
      return this.panelsList.filter((c) => c.component == this.currentPanel)[0]
        .props
    },
  },

  methods: {
    switchPanel(nextComponentName: string): string {
      if (nextComponentName) {
        return (this.currentPanel = nextComponentName)
      }
      // by default it displays the root panel component
      return (this.currentPanel = this.rootPanelComponent)
    },
  },
})
