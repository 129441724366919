



















































import Vue from 'vue'

import BaseViewport from '@/components/layouts/BaseViewport.vue'
import BaseMain from '@/components/layouts/BaseMain.vue'
import BaseHeader from '@/components/layouts/BaseHeader.vue'
import SidePanelSkeleton from '@/components/skeletons/SidePanelSkeleton.vue'
import LetterSkeleton from '@/components/skeletons/LetterSkeleton.vue'

export default Vue.extend({
  components: {
    BaseViewport,
    BaseMain,
    BaseHeader,
    SidePanelSkeleton,
    LetterSkeleton,
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  methods: {},
})
