









import Vue from 'vue'

import PatientProfile from '@/components/dashboard/panels/left/PatientProfile.vue'
import About from '@/components/dashboard/panels/left/About.vue'

export default Vue.extend({
  components: {
    PatientProfile,
    About,
  },

  props: {
    patientProfile: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      panelsList: [
        {
          component: 'PatientProfile',
          props: { patientProfile: this.patientProfile },
        },
        {
          component: 'About',
          props: undefined,
        },
      ],
      rootPanelComponent: 'PatientProfile',
      currentPanel: 'PatientProfile',
    }
  },

  computed: {
    panelProperties(): Record<symbol, string> | undefined {
      return this.panelsList.filter((c) => c.component == this.currentPanel)[0]
        .props
    },
  },

  methods: {
    switchPanel(next: string): string {
      if (next) {
        return (this.currentPanel = next)
      }
      // display root panel component
      return (this.currentPanel = this.rootPanelComponent)
    },
  },
})
